import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { Auth } from 'aws-amplify';
import {
  AnswerPerQuestion,
  AnswersTableHeader,
  AnswersTableMetaData,
  AnswersTableResponse,
  ConditionOption,
  FilterCondition,
  SortCondition
} from '../../interface/AnswersTable';
import TableHeader from './TableHeader';
import TableToolbar from './TableToolbar';
import {
  fetchFilteredAnswers,
  deleteAnswers,
  fetchQuestionnaireByHash,
  fetchQuestions
} from '../../api/index';
import ConfirmModal from '../common/ConfirmModal';
import { QuestionnaireMetaData } from '../../interface/Questionnaire';
import { ExistingQuestion, QuestionResponse } from '../../interface/Question';
import { completelyExpandQuestionResponse } from '../../common/manageQuestion';
import { createNeatFilterConditions } from '../../common/filter';

const AnswersTable: React.FC = () => {
  const [selected, setSelected] = useState<readonly number[]>([]);
  const [questionnaireId, setQuestionnaireId] = useState<number>(-1);
  const [totalCount, setTotalCount] = useState<number>(-1);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(100);
  const [headers, setHeaders] = useState<AnswersTableHeader[]>([]);
  const [metaData, setMetaData] = useState<AnswersTableMetaData[]>([]);
  const [questionnaireName, setQuestionnaireName] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [questions, setQuestions] = useState<ExistingQuestion[]>([]);
  const [filterConditions, setFilterConditions] = useState<FilterCondition[]>([
    { filterTarget: 0, condition: 'like', value: '' }
  ]);
  const [sortConditions, setSortConditions] = useState<SortCondition[]>([
    { sortTarget: 0, isAsc: false }
  ]);
  const [isUnique, setIsUnique] = useState<boolean>(true);

  const location = useLocation();
  const hash: string = location.pathname.split('/')[2];
  const initialQuestionnaireName: string | undefined =
    location.state === null
      ? undefined
      : (location.state.questionnaireName as string);

  const fetchAnswers = async (
    page: number,
    sortConditions: SortCondition[]
  ) => {
    const neatFilterConditions: FilterCondition[] =
      createNeatFilterConditions(filterConditions);
    const response: AnswersTableResponse = await fetchFilteredAnswers(
      questionnaireId,
      isUnique,
      limit,
      page * limit,
      neatFilterConditions,
      sortConditions
    );

    const headersExceptedMessages: AnswersTableHeader[] =
      response.headers.filter((header: AnswersTableHeader) => {
        const questionForHeader: ExistingQuestion | undefined = questions.find(
          (question) => question.id === header.id
        );
        return questionForHeader?.type !== 'message';
      });

    setHeaders(headersExceptedMessages);
    setMetaData(response.answers);
    setTotalCount(response.totalCount);
  };

  useEffect(() => {
    (async () => {
      if (!initialQuestionnaireName) {
        const questionnaireInfo: QuestionnaireMetaData =
          await fetchQuestionnaireByHash(hash);
        setQuestionnaireName(questionnaireInfo.name);
      } else {
        setQuestionnaireName(initialQuestionnaireName);
      }

      const response: QuestionResponse = await fetchQuestions(hash, false);
      const questionnaire: QuestionnaireMetaData =
        await fetchQuestionnaireByHash(hash);
      setQuestionnaireId(questionnaire.id);

      setQuestions(completelyExpandQuestionResponse(response.questions));
    })();
  }, []);

  useEffect(() => {
    if (questionnaireId === -1) return;
    fetchAnswers(page, sortConditions);
  }, [page, limit, isUnique, questionnaireId]);

  useEffect(() => {
    setPage(0);
  }, [isUnique]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const filters: FilterCondition[] = [];
    queryParams.forEach((value, key) => {
      const match = key.match(/filter\[(\d+)\]\[(\w+)\]/);
      if (match) {
        const index = parseInt(match[1], 10);
        // 条件は最大5つ設定可能
        if (index >= 5) return;
        const field = match[2];
        if (!filters[index]) {
          filters[index] = { filterTarget: 0, condition: 'like', value: '' };
        }
        switch (field) {
          case 'filterTarget':
            filters[index].filterTarget = parseInt(value, 10);
            break;
          case 'condition':
            filters[index].condition = value as ConditionOption;
            break;
          case 'value':
            filters[index].value = value;
            break;
        }
      }
    });
    if (filters.length > 0) {
      setFilterConditions(filters);
    }
  }, []);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLimit(+event.target.value);
    setPage(0);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = metaData.map((answer) => answer.metadataId);
      setSelected(newSelected.map(Number));
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id);
    if (selectedIndex === -1) {
      setSelected([...selected, id]);
      return;
    }
    setSelected(selected.filter((selectedId: number) => selectedId !== id));
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  const updateAnswer = (
    answers: AnswersTableMetaData,
    headers: AnswersTableHeader[]
  ): AnswerPerQuestion[] => {
    const answersWithItemId: AnswerPerQuestion[] = answers.answer.filter(
      (data) => 'itemId' in data
    );

    const sameIds: number[] = Array.from(
      new Set(
        answersWithItemId.map((answer) => {
          return answer.id;
        })
      )
    );

    const uniqueIds: number[] = Array.from(
      new Set(
        answers.answer.map((answer) => {
          return answer.id;
        })
      )
    );

    const combinedAnswers: AnswerPerQuestion[] = sameIds.map((id) => {
      return {
        id: id,
        itemName: answersWithItemId
          .filter((answer) => answer.id === id)
          .map((answer) => {
            return answer.textAnswer ? answer.textAnswer : answer.itemName;
          })
          .join(',')
      };
    });

    const formedAnswer: AnswerPerQuestion[] = uniqueIds.map((id) => {
      if (sameIds.indexOf(id) === -1) {
        const index = answers.answer.findIndex((answer) => answer.id === id);
        return {
          id: id,
          itemName: answers.answer[index].textAnswer
            ? answers.answer[index].textAnswer
            : answers.answer[index].itemName
        };
      }
      const index = combinedAnswers.findIndex((answer) => answer.id === id);
      return {
        id: id,
        itemName: combinedAnswers[index].itemName
      };
    });

    const answersWithHeadersId: AnswerPerQuestion[] = headers.map((header) => {
      const index = formedAnswer.findIndex((answer) => answer.id === header.id);
      if (index === -1) {
        return {
          id: header.id,
          itemName: ''
        };
      }
      return {
        id: header.id,
        itemName: formedAnswer[index].itemName
      };
    });
    return answersWithHeadersId;
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const displayNoTableBody = () => {
    if (totalCount === 0)
      return (
        <Box>
          <Typography sx={{ marginTop: 4 }} align="center" variant="h6">
            回答が存在しません
          </Typography>
        </Box>
      );
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Paper>
        <TableToolbar
          questionnaireName={questionnaireName}
          headers={headers}
          questionnaireId={questionnaireId}
          totalCount={totalCount}
          selected={selected}
          filterConditions={filterConditions}
          sortConditions={sortConditions}
          questions={questions}
          isUnique={isUnique}
          setIsModalOpen={setIsModalOpen}
          setFilterConditions={setFilterConditions}
          setSortConditions={setSortConditions}
          setIsUnique={setIsUnique}
          setPage={setPage}
          fetchAnswers={fetchAnswers}
        />
        <TableContainer sx={{ maxHeight: '70vh', overflow: 'auto' }}>
          <Table
            sx={{ minWidth: 750, minHeight: 5 }}
            aria-labelledby="tableTitle"
          >
            <TableHeader
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              totalCount={totalCount}
              headers={headers}
              sortConditions={sortConditions}
              setSortConditions={setSortConditions}
              setPage={setPage}
              fetchAnswers={fetchAnswers}
            />

            <TableBody>
              {metaData.map((answer: AnswersTableMetaData) => {
                const isItemSelected = isSelected(answer.metadataId);
                const labelId = `enhanced-table-checkbox-${answer.metadataId}`;
                const formedAnswer: AnswerPerQuestion[] = updateAnswer(
                  answer,
                  headers
                );
                return (
                  <TableRow
                    hover
                    onClick={(event: React.MouseEvent<unknown>) =>
                      handleClick(event, answer.metadataId)
                    }
                    role="checkbox"
                    aria-checked={isItemSelected}
                    key={answer.metadataId}
                    tabIndex={-1}
                    selected={isItemSelected}
                    sx={{ cursor: 'pointer' }}
                    className="data-row"
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId
                        }}
                      />
                    </TableCell>
                    <TableCell>{String(answer.metadataId)}</TableCell>
                    {formedAnswer.map((answer, index) => {
                      return (
                        <TableCell key={index}>{answer.itemName}</TableCell>
                      );
                    })}
                    <TableCell>{answer.userId}</TableCell>
                    <TableCell>{answer.userName}</TableCell>
                    <TableCell>{answer.answeredDate}</TableCell>
                    <TableCell>{answer.updateUser}</TableCell>
                    <TableCell>{answer.updatedUserName}</TableCell>
                    <TableCell>{answer.updatedDate}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          {displayNoTableBody()}
        </TableContainer>
        <TablePagination
          component="div"
          count={totalCount}
          rowsPerPage={limit}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <ConfirmModal
        isOpen={isModalOpen}
        question={`${selected.length}件の回答を削除してよろしいですか？`}
        handleClose={handleModalClose}
        execute={async () => {
          const user = await Auth.currentAuthenticatedUser();
          await deleteAnswers(
            [...selected],
            user.attributes.email,
            questionnaireId
          );
          window.location.reload();
        }}
        quit={handleModalClose}
      />
    </Box>
  );
};

export default AnswersTable;
