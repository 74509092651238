import {
  useEffect,
  useState,
  ChangeEvent,
  Dispatch,
  SetStateAction
} from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { SelectChangeEvent } from '@mui/material/Select';
import AddCircle from '@mui/icons-material/AddCircle';
import { MODAL_STYLE } from '../../common/style';
import ConditionForms from './ConditionForms';
import ButtonWithToolTip from '../common/ButtonWithToolTip';
import {
  ConditionOption,
  FilterCondition,
  SortCondition
} from '../../interface/AnswersTable';
import { ExistingQuestion } from '../../interface/Question';
import SortForms from './SortForms';
import {
  CONDITIONS,
  createNeatFilterConditions,
  getFilterConditionKey
} from '../../common/filter';
import { RotateLeft } from '@mui/icons-material';
import { useLocation, useSearchParams } from 'react-router-dom';

type FilterModalProps = {
  isOpen: boolean;
  filterConditions: FilterCondition[];
  sortConditions: SortCondition[];
  questions: ExistingQuestion[];
  handleClose: Function;
  setFilterConditions: Dispatch<SetStateAction<FilterCondition[]>>;
  setSortConditions: Dispatch<SetStateAction<SortCondition[]>>;
  setPage: Dispatch<SetStateAction<number>>;
  fetchAnswers: Function;
};

const boxStyle = {
  width: '50%',
  border: '2px dotted',
  borderRadius: '10px',
  padding: '1em'
};

const FilterModal: React.FC<FilterModalProps> = (props) => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const hash: string = location.pathname.split('/')[2];

  // セッションストレージから得たフィルタ条件を適用する
  const setPreviousFilterConditions = () => {
    // セッションストレージからフィルタ条件の情報を得る
    const filterConditionsInSessionStorageString: string | null =
      sessionStorage.getItem(getFilterConditionKey(hash));

    // セッションストレージから得たフィルタ条件を配列型に変更する
    const previousFilterConditions: FilterCondition[] | undefined =
      filterConditionsInSessionStorageString === null
        ? undefined
        : JSON.parse(filterConditionsInSessionStorageString);
    if (previousFilterConditions !== undefined) {
      props.setFilterConditions(previousFilterConditions);
    } else {
      props.setFilterConditions([...props.filterConditions]);
    }
  };

  const [initialFilterConditions, setInitialFilterConditions] = useState<
    FilterCondition[]
  >([]);
  const [initialSortConditions, setInitialSortConditions] = useState<
    SortCondition[]
  >([]);

  useEffect(() => {
    // location.stateがnullの場合、URL直打ちでエラーが起こる対策
    const previousPath: string | undefined =
      location.state === null
        ? undefined
        : (location.state.previousPath as string);
    // 回答編集画面から遷移した場合にセッションストレージから得たフィルタ条件を適応する
    if (previousPath === `/form-answer/${hash}`) {
      setPreviousFilterConditions();
    } else {
      setInitialFilterConditions([...props.filterConditions]);
    }
    sessionStorage.removeItem(getFilterConditionKey(hash));
  }, []);

  useEffect(() => {
    if (!props.isOpen) return;
    setInitialFilterConditions([...props.filterConditions]);
    setInitialSortConditions([...props.sortConditions]);
  }, [props.isOpen]);

  useEffect(() => {
    const neatFilterConditions = createNeatFilterConditions(
      props.filterConditions
    );
    if (neatFilterConditions.length !== 0) {
      const queryParameters = createNeatFilterConditions(
        props.filterConditions
      ).map((filterConditions, filterIndex) => {
        const filterTargetKey: string = `filter[${filterIndex}][filterTarget]`;
        const conditionKey: string = `filter[${filterIndex}][condition]`;
        const valueKey: string = `filter[${filterIndex}][value]`;

        const filterTargetQueryParameter = `${filterTargetKey}=${JSON.stringify(
          filterConditions.filterTarget
        )}`;
        const conditionQueryParameter = `${conditionKey}=${filterConditions.condition}`;
        const valueQueryParameter = `${valueKey}=${filterConditions.value}`;
        return `${filterTargetQueryParameter}&${conditionQueryParameter}&${valueQueryParameter}`;
      });
      setSearchParams(queryParameters.join('&'));
    } else {
      setSearchParams();
    }
  }, [props.filterConditions]);

  const adjustCondition = (
    targetQuestion: ExistingQuestion,
    condition: ConditionOption
  ): ConditionOption =>
    targetQuestion.type !== 'number' &&
    CONDITIONS.find(
      (conditionOption) => conditionOption.condition === condition
    )!.isForNumber
      ? 'strictEqual'
      : condition;

  const changeFilterTarget =
    (index: number, questions: ExistingQuestion[]) =>
    (event: SelectChangeEvent) => {
      const newFilterConditions: FilterCondition[] = props.filterConditions.map(
        (filterCondition: FilterCondition, i: number) => {
          if (i === index) {
            const filterTarget: number = Number(event.target.value);
            const targetQuestion: ExistingQuestion | undefined = questions.find(
              (question: ExistingQuestion) => question.id === filterTarget
            );
            return {
              ...filterCondition,
              condition:
                targetQuestion === undefined
                  ? filterCondition.condition
                  : adjustCondition(targetQuestion, filterCondition.condition),
              filterTarget
            };
          }

          return filterCondition;
        }
      );

      props.setFilterConditions(newFilterConditions);
    };

  const changeFilterCondition =
    (index: number) => (event: SelectChangeEvent) => {
      const newFilterConditions: FilterCondition[] = props.filterConditions.map(
        (filterCondition: FilterCondition, i: number) => {
          if (i === index)
            return {
              ...filterCondition,
              condition: event.target.value as ConditionOption
            };

          return filterCondition;
        }
      );

      props.setFilterConditions(newFilterConditions);
    };

  const changeFilterValue =
    (index: number) =>
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const newFilterConditions: FilterCondition[] = props.filterConditions.map(
        (filterCondition: FilterCondition, i: number) => {
          if (i === index)
            return {
              ...filterCondition,
              value: event.target.value
            };

          return filterCondition;
        }
      );

      props.setFilterConditions(newFilterConditions);
    };

  const addFilter = () =>
    props.setFilterConditions([
      ...props.filterConditions,
      { filterTarget: 0, condition: 'like', value: '' }
    ]);

  const removeFilter = (index: number) => () =>
    props.setFilterConditions(
      props.filterConditions.filter((_, i: number) => index !== i)
    );

  const changeSortTarget = (index: number) => (event: SelectChangeEvent) => {
    const newSortConditions = props.sortConditions.map(
      (sortCondition: SortCondition, i: number) => {
        if (i === index)
          return { ...sortCondition, sortTarget: Number(event.target.value) };

        return sortCondition;
      }
    );

    props.setSortConditions(newSortConditions);
  };

  const changeIsAsc = (index: number) => (event: SelectChangeEvent) => {
    const newSortConditions = props.sortConditions.map(
      (sortCondition: SortCondition, i: number) => {
        if (i === index)
          return { ...sortCondition, isAsc: event.target.value === 'true' };

        return sortCondition;
      }
    );

    props.setSortConditions(newSortConditions);
  };

  const addSortTarget = () =>
    props.setSortConditions([
      ...props.sortConditions,
      { sortTarget: 0, isAsc: true }
    ]);

  const removeSort = (index: number) => () =>
    props.setSortConditions(props.sortConditions.filter((_, i) => i !== index));

  const resetFilter = () => {
    props.setFilterConditions([
      { filterTarget: 0, condition: 'like', value: '' }
    ]);
  };

  return (
    <Modal open={props.isOpen}>
      <Box sx={MODAL_STYLE}>
        <Stack spacing={2} direction="row">
          <Box sx={boxStyle}>
            <Box display="flex" justifyContent="space-between">
              <Typography>絞り込み（条件は最大5つ設定可能）</Typography>
              <Button
                color="inherit"
                variant="outlined"
                className="filter-reset"
                startIcon={<RotateLeft />}
                onClick={() => resetFilter()}
              >
                絞り込み条件リセット
              </Button>
            </Box>
            {props.filterConditions.map(
              (filterCondition: FilterCondition, index: number) => (
                <ConditionForms
                  filterCondition={filterCondition}
                  questions={props.questions}
                  changeFilterTarget={changeFilterTarget(
                    index,
                    props.questions
                  )}
                  changeFilterCondition={changeFilterCondition(index)}
                  changeFilterValue={changeFilterValue(index)}
                  removeFilter={removeFilter(index)}
                  key={index}
                />
              )
            )}
            <ButtonWithToolTip
              title="条件追加"
              onClick={addFilter}
              icon={<AddCircle />}
              className="filter-add"
              disabled={props.filterConditions.length === 5}
            />
          </Box>
          <Box sx={boxStyle}>
            <Typography>ソート（条件は最大5つ設定可能）</Typography>
            {props.sortConditions.map(
              (sortCondition: SortCondition, index: number) => (
                <SortForms
                  sortCondition={sortCondition}
                  sortConditions={props.sortConditions.filter(
                    (_, i: number) => index !== i
                  )}
                  questions={props.questions}
                  changeSortTarget={changeSortTarget(index)}
                  changeIsAsc={changeIsAsc(index)}
                  removeSort={removeSort(index)}
                  key={index}
                />
              )
            )}
            <ButtonWithToolTip
              title="ソート列追加"
              onClick={addSortTarget}
              icon={<AddCircle />}
              className="sort-add"
              disabled={props.sortConditions.length === 5}
            />
          </Box>
        </Stack>
        <Stack
          spacing={2}
          direction="row"
          sx={{ marginTop: '0.5em', width: '100%', justifyContent: 'flex-end' }}
        >
          <Button
            className="filter-close"
            variant="contained"
            onClick={() => {
              props.setFilterConditions([...initialFilterConditions]);
              props.setSortConditions([...initialSortConditions]);
              props.handleClose();
            }}
            color="secondary"
          >
            閉じる
          </Button>
          <Button
            className="filter-save"
            variant="contained"
            onClick={() => {
              props.setPage(0);
              props.fetchAnswers(0, props.sortConditions);
              props.handleClose();
            }}
          >
            保存
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default FilterModal;
