import { Dispatch, SetStateAction } from 'react';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import {
  AnswersTableHeader,
  SortCondition
} from '../../interface/AnswersTable';
import HeaderSortButtons from './HeaderSortButtons';
import { SxProps, Theme } from '@mui/material';

type TableHeaderProps = {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  totalCount: number;
  headers: AnswersTableHeader[];
  sortConditions: SortCondition[];
  setSortConditions: Dispatch<SetStateAction<SortCondition[]>>;
  setPage: Dispatch<SetStateAction<number>>;
  fetchAnswers: Function;
};

const TableHeader: React.FC<TableHeaderProps> = (props) => {
  const onSelectAllClick = props.onSelectAllClick;
  const numSelected = props.numSelected;
  const totalCount = props.totalCount;
  const headers = props.headers;

  const sortableHeaderStyle: SxProps<Theme> = {
    ':hover': {
      '> .sort-buttons': {
        '> .sort-button': { visibility: 'visible' }
      }
    }
  };

  return (
    <TableHead
      sx={{
        position: 'sticky',
        zIndex: 2,
        top: '0',
        backgroundColor: 'white'
      }}
    >
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < totalCount}
            checked={numSelected > 0 && numSelected <= totalCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all'
            }}
          />
        </TableCell>
        <TableCell sx={sortableHeaderStyle} className="table-header-cell">
          ID
          <HeaderSortButtons
            sortTarget={0}
            sortConditions={props.sortConditions}
            setSortConditions={props.setSortConditions}
            setPage={props.setPage}
            fetchAnswers={props.fetchAnswers}
          />
        </TableCell>
        {headers.map((header, index) => (
          <TableCell
            sx={sortableHeaderStyle}
            key={index}
            className="table-header-cell"
          >
            {`[Q${index + 1}] ${header.name}`}
            {header.items === undefined ? (
              <HeaderSortButtons
                sortTarget={header.id}
                sortConditions={props.sortConditions}
                setSortConditions={props.setSortConditions}
                setPage={props.setPage}
                fetchAnswers={props.fetchAnswers}
              />
            ) : (
              <></>
            )}
          </TableCell>
        ))}
        <TableCell sx={{ minWidth: 120 }}>回答者メールアドレス</TableCell>
        <TableCell sx={{ minWidth: 120 }}>回答者氏名</TableCell>
        <TableCell sx={{ minWidth: 120 }}>回答日</TableCell>
        <TableCell sx={{ minWidth: 120 }}>更新者メールアドレス</TableCell>
        <TableCell sx={{ minWidth: 120 }}>更新者氏名</TableCell>
        <TableCell sx={{ minWidth: 120 }}>更新日</TableCell>
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
