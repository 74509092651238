import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import QuestionForm from './QuestionForm';
import Accordion from '../common/Accordion';
import {
  fetchQuestions,
  fetchQuestionnaireByHash,
  fetchPreviousAnswers,
  fetchAnswersByMetadataId,
  fetchTemporarySavedAnswer
} from '../../api';
import {
  FetchedQuestion,
  GroupedQuestion,
  Question,
  QuestionResponse
} from '../../interface/Question';
import { Inheritance } from '../../interface/Inheritance';
import { Answer, PreviousAnswerQueryParam } from '../../interface/Answer';
import { QuestionnaireMetaData } from '../../interface/Questionnaire';
import { useSelector } from '../../redux/store';
import { useDispatch } from 'react-redux';
import {
  clear,
  initialize,
  overwriteAnswers,
  switchIsKeyQuestionChanged,
  switchIsAnswersSet
} from '../../redux/slice/AnswerSlice';
import SubmitButton from './SubmitButton';
import KeyQuestionChangeModal from './KeyQuestionChangeModal';
import AnswerQuotationButton from './AnswerQuotationButton';

type QuestionnaireFormProps = {
  sendAnswer: Function;
  tempPath: string;
  isEdited?: boolean;
  afterSubmitPage?: string;
  isInheritance: boolean;
  hiddenQuestionIds: number[];
  hiddenGroupIds: number[];
};

const extractAnswersForTargetQuestion = (
  answers: Answer[],
  questionId: number
): Answer[] => {
  return answers.filter((answer: Answer) => answer.questionId === questionId);
};

const QuestionnaireForm: React.FC<QuestionnaireFormProps> = (props) => {
  const isInheritance: boolean = props.isInheritance;

  const location = useLocation();
  const hash: string = location.pathname.split('/')[2];

  const answers: Answer[] = useSelector((state) => state.answer.answers);

  const isKeyQuestionChanged: boolean = useSelector(
    (state) => state.answer.isKeyQuestionChanged
  );
  const previousAnswer: Answer | undefined = useSelector(
    (state) => state.answer.previousAnswer
  );
  const inheritance: Inheritance | undefined = useSelector(
    (state) => state.answer.inheritance
  );
  const questions: FetchedQuestion[] = useSelector(
    (state) => state.answer.questions
  );
  const questionnaireName: string = useSelector(
    (state) => state.answer.questionnaireName
  );
  const questionnaireId: number = useSelector(
    (state) => state.answer.questionnaireId
  );

  const [isPublic, setIsPublic] = useState<boolean>(false);
  // キーとなる質問を変更したとき
  const [isKeyModalOpen, setIsKeyModalOpen] = useState<boolean>(false);
  const [temporarySavedAnswers, setTemporarySavedAnswers] = useState<Answer[]>(
    []
  );
  const [disabledKeyQuestionChange, setDisabledKeyQuestionChange] =
    useState<boolean>(false);
  const [accordionStats, setAccordionStats] = useState<boolean[]>([]);

  const dispatch = useDispatch();
  const setAnswers = (answers: Answer[]) => dispatch(overwriteAnswers(answers));
  const switchKeyQuestionChangedFlag = (flag: boolean) =>
    dispatch(switchIsKeyQuestionChanged(flag));
  const switchSetAnswersChanged = () => dispatch(switchIsAnswersSet());
  const initializeState = (
    temporarySavedAnswers: Answer[],
    questions: FetchedQuestion[],
    questionnaireName: string,
    questionnaireId: number,
    inheritance?: Inheritance
  ) =>
    dispatch(
      initialize({
        temporarySavedAnswers,
        questions,
        inheritance,
        questionnaireName,
        questionnaireId
      })
    );
  const clearState = () => dispatch(clear());

  // ページが読み込まれたとき
  useEffect(() => {
    (async () => {
      clearState();
      const metadata: QuestionnaireMetaData = await fetchQuestionnaireByHash(
        hash
      );
      const response: QuestionResponse = await fetchQuestions(
        metadata.id,
        false
      );
      const temporaryAnswers: Answer[] = await fetchTemporarySavedAnswer(
        metadata.id
      );
      initializeState(
        temporaryAnswers,
        response.questions,
        metadata.name,
        metadata.id,
        response.inheritance
      );
      setIsPublic(response.isPublic);
      setAccordionStats(
        response.questions.map(() => {
          return false;
        })
      );

      if (location.state !== null && location.state.answerId !== null) {
        const fetchedInitialAnswers: Answer[] = await fetchAnswersByMetadataId(
          location.state.answerId
        );
        setAnswers(fetchedInitialAnswers);
        switchSetAnswersChanged();
        return;
      }

      if (
        temporaryAnswers.length === 0 &&
        isInheritance &&
        response.inheritance
      )
        reflectPreviousAnswer(response.inheritance);
    })();
  }, [hash]);

  const handleKeyModalOpen = () => setIsKeyModalOpen(true);
  const handleKeyModalClose = () => {
    setIsKeyModalOpen(false);
    setTemporarySavedAnswers([]);
  };

  useEffect(() => {
    if (!isKeyQuestionChanged) return;
    if (disabledKeyQuestionChange) {
      setDisabledKeyQuestionChange(false);
      switchKeyQuestionChangedFlag(false);
      return;
    }
    (async () => {
      const keyAnswer: Answer = answers.find(
        (answer: Answer) => answer.questionId === inheritance?.questionId
      )!;
      const temporaryAnswers: Answer[] = await fetchTemporarySavedAnswer(
        questionnaireId,
        keyAnswer.itemId,
        keyAnswer.textAnswer
      );
      setTemporarySavedAnswers(temporaryAnswers);

      if (answers.length === 1 && temporaryAnswers.length === 0) {
        keyQuestionChange();
      } else {
        handleKeyModalOpen();
      }
    })();
  }, [isKeyQuestionChanged]);

  const keyQuestionChange = () => {
    if (isKeyQuestionChanged && isInheritance && inheritance) {
      reflectPreviousAnswer(inheritance);
      switchKeyQuestionChangedFlag(false);
    }
    handleKeyModalClose();
  };

  const keyChangeCancel = () => {
    switchKeyQuestionChangedFlag(false);
    revertPreviousKeyAnswer(inheritance!);
    handleKeyModalClose();
  };

  const revertPreviousKeyAnswer = async (inheritance: Inheritance) => {
    const answersRemovedKeyAnswer: Answer[] = answers.filter(
      (answer: Answer) => answer.questionId !== inheritance.questionId
    );
    const revertedAnswer: Answer[] = [
      ...answersRemovedKeyAnswer,
      previousAnswer!
    ];
    setAnswers(revertedAnswer);
  };

  const reflectPreviousAnswer = async (inheritance: Inheritance) => {
    // 「同一ユーザーの前回回答を参照する」にチェックが入っていて「キーとなる質問」が指定されていない場合
    if (inheritance.isSameUser && inheritance.questionId === undefined) {
      const queryParameter: PreviousAnswerQueryParam = { hash };
      const previousAnswers: Answer[] | undefined = await fetchPreviousAnswers(
        queryParameter,
        inheritance!.isSameUser
      );
      if (previousAnswers !== undefined) {
        setAnswers(previousAnswers);
        switchSetAnswersChanged();
      }
      return;
    }

    const itemId: number | undefined = answers.find(
      (answer: Answer) => answer.questionId === inheritance.questionId
    )?.itemId;
    const textAnswer: string | undefined = answers.find(
      (answer: Answer) => answer.questionId === inheritance.questionId
    )?.textAnswer;

    if (
      inheritance.questionId !== undefined &&
      itemId === undefined &&
      textAnswer === undefined
    )
      return;

    const queryParameter: PreviousAnswerQueryParam = {
      hash,
      questionId: inheritance.questionId,
      itemId,
      textAnswer
    };

    const previousAnswers: Answer[] | undefined = await fetchPreviousAnswers(
      queryParameter,
      inheritance.isSameUser
    );

    if (previousAnswers !== undefined) {
      setAnswers(
        mergeKeyQuestion(inheritance, previousAnswers, queryParameter)
      );
      switchSetAnswersChanged();
    } else {
      setAnswers([
        {
          questionId: queryParameter.questionId!,
          itemId: queryParameter?.itemId,
          textAnswer: queryParameter?.textAnswer
        }
      ]);
      switchSetAnswersChanged();
    }
  };

  // fetchPreviousAnswersでキーの質問IDに相当する回答情報が取得されていなければpreviousAnswersにマージする
  const mergeKeyQuestion = (
    inheritance: Inheritance,
    previousAnswers: Answer[],
    queryParameter: PreviousAnswerQueryParam
  ): Answer[] => {
    const isMergeKeyQuestion: boolean =
      inheritance.questionId === undefined
        ? false
        : previousAnswers
            .filter(
              (previousAnswer: Answer) =>
                previousAnswer.questionId !== undefined
            )
            .find(
              (previousAnswer: Answer) =>
                previousAnswer.questionId === inheritance.questionId
            ) === undefined
        ? true
        : false;

    if (isMergeKeyQuestion) {
      return [
        ...previousAnswers,
        {
          questionId: queryParameter.questionId!,
          itemId: queryParameter?.itemId,
          textAnswer: queryParameter?.textAnswer
        }
      ];
    }
    return previousAnswers;
  };

  const onClickAccordion =
    (index: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      const newAccordionStats: boolean[] = accordionStats.map(
        (accordionStat: boolean, i: number) => {
          if (i === index) return isExpanded;
          return accordionStat;
        }
      );

      return setAccordionStats(newAccordionStats);
    };

  if (questions.length === 0) {
    return <></>;
  }

  const haveAnsweredAllRequiredQuestionsInGroup = (
    questionsInGroup: Question[]
  ): boolean => {
    const questionIds: number[] = questionsInGroup
      .filter((question: Question) => question.required)
      .map((question: Question) => question.id);
    const answeredIds: number[] = answers.map(
      (answer: Answer) => answer.questionId
    );

    return questionIds.every((questionId: number) =>
      answeredIds.includes(questionId)
    );
  };

  return (
    <>
      <h2>{questionnaireName}</h2>
      <AnswerQuotationButton
        isPublic={isPublic}
        setDisabledKeyQuestionChange={setDisabledKeyQuestionChange}
      />
      {questions.map((question: Question | GroupedQuestion, index: number) => {
        if ('group' in question) {
          return (
            <Accordion
              headline={question.group}
              headlineHelperText={
                haveAnsweredAllRequiredQuestionsInGroup(question.questions)
                  ? ''
                  : `グループ内に未回答の質問があります。${
                      accordionStats[index] !== undefined &&
                      accordionStats[index]
                        ? ''
                        : 'クリックしてください。'
                    }`
              }
              content={question.questions.map((questionInGroup: Question) => {
                return (
                  <QuestionForm
                    question={questionInGroup}
                    answers={extractAnswersForTargetQuestion(
                      answers,
                      questionInGroup.id
                    )}
                    key={questionInGroup.id}
                    disabled={
                      props.hiddenQuestionIds.includes(questionInGroup.id) ||
                      props.hiddenGroupIds.includes(question.groupId)
                    }
                  />
                );
              })}
              sx={{
                marginTop: '0.5em',
                backgroundColor: props.hiddenGroupIds.includes(question.groupId)
                  ? '#EEEEEE'
                  : undefined
              }}
              headlineSx={{
                color: props.hiddenGroupIds.includes(question.groupId)
                  ? '#CCCCCC'
                  : undefined
              }}
              isOpen={
                accordionStats[index] === undefined
                  ? false
                  : accordionStats[index]
              }
              onChange={onClickAccordion(index)}
              key={`group-${question.groupId}`}
            />
          );
        }
        return (
          <QuestionForm
            question={question}
            answers={extractAnswersForTargetQuestion(answers, question.id)}
            disabled={props.hiddenQuestionIds.includes(question.id)}
            key={question.id}
          />
        );
      })}
      <SubmitButton
        sendAnswer={props.sendAnswer}
        tempPath={props.tempPath}
        isEdited={props.isEdited}
        afterSubmitPage={props.afterSubmitPage}
        hiddenQuestionIds={props.hiddenQuestionIds}
        hiddenGroupIds={props.hiddenGroupIds}
      />
      <KeyQuestionChangeModal
        isOpen={isKeyModalOpen}
        existTemporarySavedAnswers={temporarySavedAnswers.length > 0}
        handleClose={keyChangeCancel}
        reflectPreviousAnswers={keyQuestionChange}
        reflectTemporarySavedAnswers={() => {
          setAnswers(temporarySavedAnswers);
          switchSetAnswersChanged();
          switchKeyQuestionChangedFlag(false);
          handleKeyModalClose();
        }}
        quit={keyChangeCancel}
      />
    </>
  );
};

export default QuestionnaireForm;
