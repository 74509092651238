import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { User } from '../../interface/User';
import { Auth } from 'aws-amplify';

type FormAuthoritiesProps = {
  users: User[];
  checkedIds: string[];
  setCheckedIds: Dispatch<SetStateAction<string[]>>;
};

type UserTableHeader = {
  key: 'name' | 'email';
  name: string;
};

const HEADERS: UserTableHeader[] = [
  { key: 'name', name: 'ユーザー名' },
  { key: 'email', name: 'メールアドレス' }
];

const FormAuthorities: React.FC<FormAuthoritiesProps> = (props) => {
  const [signingInUserId, setSingningInUserId] = useState<string>('');

  useEffect(() => {
    (async () => {
      const currentUser = await Auth.currentAuthenticatedUser();
      setSingningInUserId(currentUser.username);
    })();
  });

  return (
    <FormGroup sx={{ marginLeft: '0.5em' }}>
      <Table>
        <TableHead>
          <TableRow>
            {HEADERS.map((header: UserTableHeader) => (
              <TableCell key={header.key}>{header.name}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.users.map((user: User) => (
            <TableRow key={user.id}>
              <TableCell>
                <FormControlLabel
                  control={<Checkbox disabled={user.id === signingInUserId} />}
                  label={user.name}
                  value={user.email}
                  key={user.id}
                  checked={props.checkedIds.includes(user.email)}
                  onChange={() => {
                    if (props.checkedIds.includes(user.email)) {
                      props.setCheckedIds(
                        props.checkedIds.filter(
                          (email: string) => email !== user.email
                        )
                      );
                      return;
                    }

                    props.setCheckedIds([...props.checkedIds, user.email]);
                  }}
                />
              </TableCell>
              {HEADERS.filter(
                (header: UserTableHeader) => header.key !== 'name'
              ).map((header: UserTableHeader) => (
                <TableCell
                  key={header.key}
                  sx={
                    user.id === signingInUserId
                      ? { color: 'rgba(0, 0, 0, 0.38)' }
                      : {}
                  }
                >
                  {user[header.key]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </FormGroup>
  );
};

export default FormAuthorities;
