import { createSlice } from '@reduxjs/toolkit';
import { Inheritance } from '../../interface/Inheritance';
import {
  EditingAnswerRange,
  EditingQuestion,
  EditingQuestionItem,
  ExistingQuestion,
  ExistingQuestionItem,
  NewQuestion,
  NewQuestionItem,
  QuestionType,
  QuestionCondition,
  RangeType,
  QuestionGroup,
  EditingQuestionOrGroup,
  GroupedEditingQuestion
} from '../../interface/Question';
import { DESCRIBABLE_QUESTION_TYPES } from '../../common/questionType';
import {
  getGroupedIndex,
  getGroupedQuestionsExceptTarget,
  getInsertionIndex,
  getNewId,
  getQuestionIndexFromUniqueIdentifier,
  groupQuestions
} from '../../common/manageQuestion';
import { updateNextTo } from '../../common/group';
import { arrayMove } from '@dnd-kit/sortable';

const expandGroupedQuestions = (groupOrQuestion: EditingQuestionOrGroup) => {
  if ('groupName' in groupOrQuestion) return groupOrQuestion.questions;
  return groupOrQuestion as EditingQuestion;
};

export const questionnaireSlice = createSlice({
  name: 'questionnaire',
  initialState: {
    name: '' as string,
    initialQuestionnaireName: '' as string,
    questions: [] as EditingQuestion[],
    groups: [] as QuestionGroup[],
    initialQuestions: [] as ExistingQuestion[],
    initialGroups: [] as QuestionGroup[],
    editingQuestion: {
      id: -1,
      question: '',
      type: 'select',
      required: false,
      headline: '',
      canInherit: false,
      isOpen: true
    } as EditingQuestion,
    editingIndex: -1 as number,
    focusingIndex: -1 as number,
    inheritance: {
      questionId: 0,
      isSameUser: true
    } as Inheritance,
    initialInheritance: { questionId: 0, isSameUser: true } as Inheritance,
    isPublic: true,
    initialIsPublic: true,
    deletedGroupIds: [] as number[],
    isSaved: false
  },
  reducers: {
    clearState: (state) => {
      state.name = '';
      state.initialQuestionnaireName = '';
      state.questions = [];
      state.initialQuestions = [];
      state.initialGroups = [];
      state.groups = [];
      state.editingQuestion = {
        id: -1,
        question: '',
        type: 'select',
        required: false,
        headline: '',
        canInherit: false,
        isOpen: true
      };
      state.editingIndex = -1;
      state.focusingIndex = -1;
      state.inheritance = {
        questionId: 0,
        isSameUser: true
      };
      state.initialInheritance = {
        questionId: 0,
        isSameUser: true
      };
      state.isPublic = true;
      state.initialIsPublic = true;
      state.deletedGroupIds = [];
      state.isSaved = false;
    },
    changeQuestionnaireName: (state, action) => {
      state.name = action.payload;
    },
    initialize: (
      state,
      action: {
        payload: {
          questions: EditingQuestion[];
          isPublic: boolean;
          groups?: QuestionGroup[];
          inheritance?: Inheritance;
          name?: string;
        };
      }
    ) => {
      state.initialQuestions = action.payload.questions as ExistingQuestion[];
      state.initialGroups =
        action.payload.groups === undefined ? [] : action.payload.groups;
      state.questions = action.payload.questions;
      state.isPublic = action.payload.isPublic;
      state.initialIsPublic = action.payload.isPublic;
      state.groups =
        action.payload.groups === undefined ? [] : action.payload.groups;

      if (action.payload.inheritance !== undefined) {
        state.inheritance = action.payload.inheritance;
        state.initialInheritance = action.payload.inheritance;
      }
      const initialName: string =
        action.payload.name === undefined ? '' : action.payload.name;
      state.name = initialName;
      state.initialQuestionnaireName = initialName;

      state.isSaved = false;
    },
    changeQuestionType: (state, action) => {
      const type: QuestionType = action.payload as QuestionType;

      state.editingQuestion = {
        ...state.editingQuestion,
        type,
        items:
          state.editingQuestion.items === undefined &&
          !DESCRIBABLE_QUESTION_TYPES.includes(type)
            ? []
            : state.editingQuestion.items
      };
    },
    changeInheritance: (state, action) => {
      state.inheritance = action.payload;
    },
    changeFocusingIndex: (state, action: { payload: number; type: string }) => {
      state.focusingIndex = state.questions.findIndex(
        (question: EditingQuestion) => question.id === action.payload
      );
    },
    addQuestion: (
      state,
      action: {
        payload: {
          type: QuestionType;
          groupedIndex?: number;
        };
      }
    ) => {
      const questions: EditingQuestion[] =
        state.editingIndex === -1
          ? state.questions
          : state.questions.map((question: EditingQuestion, index: number) => {
              if (index === state.editingIndex) return state.editingQuestion;
              return question;
            });

      const type: QuestionType = action.payload.type;
      const newQuestion: NewQuestion = {
        id: getNewId(-1, state.questions),
        question: '',
        type,
        required: false,
        headline: '',
        items: DESCRIBABLE_QUESTION_TYPES.includes(type) ? undefined : [],
        canInherit: false,
        isOpen: true
      };
      state.editingQuestion = newQuestion;
      const groupedQuestions: EditingQuestionOrGroup[] = groupQuestions(
        questions,
        state.groups
      );

      const insertionIndex: number = getInsertionIndex(
        groupedQuestions,
        groupedQuestions.length,
        action.payload.groupedIndex
      );
      const insertedGroupedQuestions: EditingQuestionOrGroup[] =
        groupedQuestions.toSpliced(insertionIndex, 0, newQuestion);
      const updatedQuestions: EditingQuestion[] =
        insertedGroupedQuestions.flatMap(expandGroupedQuestions);
      const newEditingIndex: number = updatedQuestions.findIndex(
        (question: EditingQuestion) => question.id === newQuestion.id
      );
      state.editingIndex = newEditingIndex;
      state.focusingIndex = newEditingIndex;
      state.questions = updatedQuestions;

      // 質問を任意の箇所に挿入する場合、その質問を前の質問としているグループの向き先を変更する
      if (action.payload.groupedIndex !== undefined) {
        state.groups = updateNextTo(insertedGroupedQuestions, state.groups);
      }
    },
    manageIsOpen: (
      state,
      action: {
        payload: { id: number; isQuestion: boolean; isOpen?: boolean };
      }
    ) => {
      const switchIsOpen = <T extends { id: number; isOpen: boolean }>(
        element: T
      ): T => {
        if (element.id === action.payload.id)
          return {
            ...element,
            isOpen:
              action.payload.isOpen !== undefined
                ? action.payload.isOpen
                : !element.isOpen
          };

        return element;
      };

      if (action.payload.isQuestion) {
        state.questions = state.questions.map(switchIsOpen);
        return;
      }

      state.groups = state.groups.map(switchIsOpen);
    },
    updateEditingQuestion: (
      state,
      action: { payload: { key: string; value: any } }
    ) => {
      state.editingQuestion = {
        ...state.editingQuestion,
        [action.payload.key]: action.payload.value
      };
    },
    startEdit: (state, action) => {
      if (state.editingIndex !== -1) {
        const edited: EditingQuestion[] = state.questions.map(
          (question: EditingQuestion, i: number) => {
            if (i === state.editingIndex) return state.editingQuestion;

            return question;
          }
        );

        state.questions = edited;
      }

      state.editingIndex = action.payload;
      state.editingQuestion = { ...state.questions[action.payload] };
    },
    quitEdit: (state) => {
      const edited: EditingQuestion[] = state.questions.map(
        (question: EditingQuestion, i: number) => {
          if (i === state.editingIndex) return state.editingQuestion;

          return question;
        }
      );

      state.questions = edited;

      state.editingIndex = -1;
      state.editingQuestion = {
        id: -1,
        question: '',
        type: 'select',
        required: false,
        headline: '',
        canInherit: false,
        isOpen: true
      };
    },
    replaceEditingQuestion: (state, action: { payload: EditingQuestion }) => {
      state.editingQuestion = action.payload;
    },
    switchOrder: (
      state,
      action: { payload: { isUpward: boolean; index: number } }
    ) => {
      const isUpward: boolean = action.payload.isUpward;
      const index: number = action.payload.index;
      const editingId: number | undefined =
        state.editingIndex === -1
          ? undefined
          : state.questions[state.editingIndex].id;
      const targetQuestionId: number = state.questions[index].id;
      const groupedQuestions: EditingQuestionOrGroup[] = groupQuestions(
        state.questions,
        state.groups
      );
      const targetQuestionIndex: number = groupedQuestions.findIndex(
        (groupOrQuestion: EditingQuestionOrGroup) =>
          'question' in groupOrQuestion &&
          groupOrQuestion.id === targetQuestionId
      );

      const createEditingIndex = (
        switchedQuestions: EditingQuestion[]
      ): number =>
        switchedQuestions.findIndex(
          (question: EditingQuestion) => question.id === editingId
        );

      // group内の質問に対する処理
      if (targetQuestionIndex === -1) {
        const insertionIndex: number = isUpward ? index - 1 : index + 1;
        const questionsExceptTarget: EditingQuestion[] = state.questions.filter(
          (_, i: number) => i !== index
        );
        const insertedQuestions: EditingQuestion[] =
          questionsExceptTarget.toSpliced(
            insertionIndex,
            0,
            state.questions[index]
          );
        const switchedGroupedQuestions: EditingQuestionOrGroup[] =
          groupQuestions(insertedQuestions, state.groups);

        if (editingId !== undefined) {
          state.editingIndex = createEditingIndex(insertedQuestions);
        }

        state.questions = insertedQuestions;
        state.groups = updateNextTo(switchedGroupedQuestions, state.groups);
        // グループ外の質問に対する処理
      } else {
        const insertionIndex: number = isUpward
          ? targetQuestionIndex - 1
          : targetQuestionIndex + 1;

        const groupedQuestionsExceptTarget: EditingQuestionOrGroup[] =
          groupedQuestions.filter(
            (_, index: number) => index !== targetQuestionIndex
          );

        const insertedGroupedQuestions: EditingQuestionOrGroup[] =
          groupedQuestionsExceptTarget.toSpliced(
            insertionIndex,
            0,
            state.questions[index]
          );

        const switchedQuestions: EditingQuestion[] =
          insertedGroupedQuestions.flatMap(expandGroupedQuestions);

        if (editingId !== undefined) {
          state.editingIndex = createEditingIndex(switchedQuestions);
        }

        state.questions = switchedQuestions;
        state.groups = updateNextTo(insertedGroupedQuestions, state.groups);
      }
      state.focusingIndex = state.questions.findIndex(
        (question: EditingQuestion) => question.id === targetQuestionId
      );
    },
    changeOrder: (
      state,
      action: {
        payload: { activeId: string; overId: string };
        type: string;
      }
    ) => {
      const overGroupedIndex: number = getGroupedIndex(action.payload.overId);
      const activeGroupedIndex: number = getGroupedIndex(
        action.payload.activeId
      );
      const editingId: number | undefined =
        state.editingIndex === -1
          ? undefined
          : state.questions[state.editingIndex].id;

      const groupedQuestions: EditingQuestionOrGroup[] = groupQuestions(
        state.questions,
        state.groups
      );

      const target: EditingQuestionOrGroup =
        groupedQuestions[activeGroupedIndex];
      const groupedQuestionsExceptTarget: EditingQuestionOrGroup[] =
        groupedQuestions.filter(
          (_, index: number) => index !== activeGroupedIndex
        );

      const insertedGroupedQuestions: EditingQuestionOrGroup[] =
        groupedQuestionsExceptTarget.toSpliced(overGroupedIndex, 0, target);

      const reorderedQuestions: EditingQuestion[] =
        insertedGroupedQuestions.flatMap(expandGroupedQuestions);
      state.questions = reorderedQuestions;

      state.groups = updateNextTo(insertedGroupedQuestions, state.groups);

      if (editingId !== undefined) {
        state.editingIndex = reorderedQuestions.findIndex(
          (question: EditingQuestion) => question.id === editingId
        );
      }
    },
    changeOrderWithInnerQuestion: (
      state,
      action: {
        payload: { activeId: string; overId: string };
        type: string;
      }
    ) => {
      // 編集中のときeditingIDに対象のidを代入。（編集中でないとき：undefined）
      const editingId: number | undefined =
        state.editingIndex === -1
          ? undefined
          : state.questions[state.editingIndex].id;
      // questionとgroupをグループ化
      const groupedQuestions: EditingQuestionOrGroup[] = groupQuestions(
        state.questions,
        state.groups
      );
      // activeの質問を抜き出す
      const activeQuestionIndex: number = getQuestionIndexFromUniqueIdentifier(
        action.payload.activeId
      );
      const overQuestionIndex: number = getQuestionIndexFromUniqueIdentifier(
        action.payload.overId
      );
      const overTarget: EditingQuestion = state.questions[overQuestionIndex];
      const originalActiveTarget: EditingQuestion =
        state.questions[activeQuestionIndex].id === editingId
          ? state.editingQuestion
          : state.questions[activeQuestionIndex];
      const filterQuestionConditions = (
        original: EditingQuestion
      ): QuestionCondition[] | undefined => {
        if (
          original.questionConditions === undefined ||
          original.groupId === undefined ||
          original.groupId === overTarget.groupId
        )
          return original.questionConditions?.filter(
            (condition: QuestionCondition) =>
              condition.childGroupId !== overTarget.groupId
          );

        const innerQuestionIds: number[] = state.questions
          .filter(
            (question: EditingQuestion) => question.groupId === original.groupId
          )
          .map((question: EditingQuestion) => question.id);
        return original.questionConditions.filter(
          (condition: QuestionCondition) =>
            condition.childQuestionId === undefined ||
            !innerQuestionIds.includes(condition.childQuestionId)
        );
      };
      const filteredQuestionConditions: QuestionCondition[] | undefined =
        filterQuestionConditions(originalActiveTarget);
      const activeTarget: EditingQuestion = {
        ...originalActiveTarget,
        groupId: overTarget.groupId,
        questionConditions: filteredQuestionConditions
      };

      // groupedQuestionsからactiveTargetを除外した配列を作る
      const groupedQuestionsNotDragging: EditingQuestionOrGroup[] =
        getGroupedQuestionsExceptTarget(groupedQuestions, activeTarget.id);

      // groupedQuestionsNotDraggingにactiveTargetを挿入
      const insertedGroupedQuestions: EditingQuestionOrGroup[] =
        groupedQuestionsNotDragging.map(
          (questionOrGroup: EditingQuestionOrGroup) => {
            if (
              'question' in questionOrGroup ||
              questionOrGroup.groupId !== overTarget.groupId
            )
              return questionOrGroup;

            const overQuestionIndexInGroup: number =
              questionOrGroup.questions.findIndex(
                (question: EditingQuestion) => question.id === overTarget.id
              );
            const insertionIndex: number =
              activeQuestionIndex < overQuestionIndex
                ? overQuestionIndexInGroup + 1
                : overQuestionIndexInGroup;

            const insertedQuestions: EditingQuestion[] =
              questionOrGroup.questions.toSpliced(
                insertionIndex,
                0,
                activeTarget
              );

            return { ...questionOrGroup, questions: insertedQuestions };
          }
        );

      // insertedGroupedQuestionsから質問を展開して抜き出し、その配列をstate.questionsに代入
      const reorderedQuestions: EditingQuestion[] =
        insertedGroupedQuestions.flatMap(expandGroupedQuestions);
      state.questions = reorderedQuestions;

      // グループの前後関係の情報を最新化
      state.groups = updateNextTo(insertedGroupedQuestions, state.groups);

      if (editingId !== undefined) {
        state.editingIndex = reorderedQuestions.findIndex(
          (question: EditingQuestion) => question.id === editingId
        );
      }
      if (editingId === activeTarget.id) {
        state.editingQuestion = {
          ...state.editingQuestion,
          groupId: overTarget.groupId,
          questionConditions: filteredQuestionConditions
        };
      }
    },
    deleteQuestion: (state, action) => {
      const index: number = action.payload;
      state.focusingIndex =
        'isDeleted' in state.questions[index] ? state.questions.length - 1 : -1;

      const groupedQuestions: EditingQuestionOrGroup[] = groupQuestions(
        state.questions,
        state.groups
      );
      const deletedQuestion: EditingQuestion =
        'isDeleted' in state.questions[index]
          ? {
              ...(state.editingQuestion as ExistingQuestion),
              isDeleted: true,
              groupId: undefined,
              questionConditions: undefined
            }
          : {
              ...state.questions[index],
              groupId: undefined,
              questionConditions: undefined
            };
      const groupedQuestionsExceptDeleteTarget: EditingQuestionOrGroup[] =
        groupedQuestions
          .filter(
            (groupOrQuestion: EditingQuestionOrGroup) =>
              !(
                'question' in groupOrQuestion &&
                groupOrQuestion.id === deletedQuestion.id
              )
          )
          .map((groupOrQuestion: EditingQuestionOrGroup) => {
            if ('groupName' in groupOrQuestion)
              return {
                ...groupOrQuestion,
                questions: groupOrQuestion.questions.filter(
                  (question: EditingQuestion) =>
                    question.id !== deletedQuestion.id
                )
              };

            return groupOrQuestion;
          });
      const deletedGroupedQuestion: EditingQuestionOrGroup[] =
        'isDeleted' in state.questions[index]
          ? [...groupedQuestionsExceptDeleteTarget, deletedQuestion]
          : groupedQuestionsExceptDeleteTarget;

      state.questions = deletedGroupedQuestion
        .flatMap(expandGroupedQuestions)
        .map((question: EditingQuestion) => {
          if (
            question.questionConditions === undefined ||
            question.questionConditions.length === 0
          )
            return question;

          return {
            ...question,
            questionConditions: question.questionConditions.filter(
              (condition: QuestionCondition) =>
                condition.childQuestionId !== deletedQuestion.id
            )
          };
        });

      state.groups = updateNextTo(deletedGroupedQuestion, state.groups);

      state.editingIndex = -1;
      state.editingQuestion = {
        id: -1,
        question: '',
        type: 'select',
        required: false,
        headline: '',
        canInherit: false,
        isOpen: true
      };
    },
    restoreQuestion: (state, action) => {
      const index: number = action.payload;
      const restored: EditingQuestion[] = state.questions.map(
        (question: EditingQuestion, i: number) => {
          if (i !== index) return question;

          return { ...question, isDeleted: false };
        }
      );

      const deletedQuestions = restored.filter(
        (question: EditingQuestion) =>
          'isDeleted' in question && question.isDeleted
      );
      const notDeletedQuestions = restored.filter(
        (question: EditingQuestion) =>
          !('isDeleted' in question && question.isDeleted)
      );

      state.focusingIndex = notDeletedQuestions.length - 1;
      state.editingIndex = notDeletedQuestions.length - 1;
      state.editingQuestion = { ...restored[index] };
      state.questions = [...notDeletedQuestions, ...deletedQuestions];
    },
    addQuestionItem: (state, action) => {
      const newItemNames: string[] = action.payload;
      const newItemLength: number = state.questions
        .filter((question: EditingQuestion) => question.items !== undefined)
        .map((question: EditingQuestion) => question.items!)
        .flat()
        .filter((item: EditingQuestionItem) => !('isDeleted' in item)).length;
      const editingNewItemLength: number =
        state.editingQuestion.items !== undefined
          ? state.editingQuestion.items.filter(
              (item: EditingQuestionItem) => !('isDeleted' in item)
            ).length
          : 0;
      const newItems: EditingQuestionItem[] = newItemNames.map(
        (name: string, index: number) => ({
          id: -(newItemLength + editingNewItemLength + index + 1),
          name,
          isDescription: false
        })
      );
      const items: EditingQuestionItem[] = [
        ...state.editingQuestion.items!,
        ...newItems
      ];
      const itemIndex: number = state.editingQuestion.items!.findIndex(
        (item: EditingQuestionItem) => 'isDeleted' in item && item.isDeleted
      );

      if (itemIndex === -1) {
        state.editingQuestion = { ...state.editingQuestion, items };
        return;
      }

      const copiedItem: EditingQuestionItem[] = [
        ...state.editingQuestion.items!
      ];
      newItems.map((newItem: EditingQuestionItem, j: number) =>
        copiedItem.splice(itemIndex + j, 0, newItem)
      );

      state.editingQuestion = { ...state.editingQuestion, items: copiedItem };
    },
    updateQuestionItem: (
      state,
      action: {
        payload: { key: string; value: string | boolean; itemIndex: number };
        type: string;
      }
    ) => {
      const updatedItems: EditingQuestionItem[] =
        state.editingQuestion.items!.map((item: NewQuestionItem, i: number) => {
          if (i !== action.payload.itemIndex) return item;

          return { ...item, [action.payload.key]: action.payload.value };
        });

      state.editingQuestion = { ...state.editingQuestion, items: updatedItems };
    },
    switchItemOrder: (
      state,
      action: {
        payload: { isUpward: boolean; itemIndex: number };
        type: string;
      }
    ) => {
      const isUpward: boolean = action.payload.isUpward;
      const itemIndex: number = action.payload.itemIndex;
      const questionItems: EditingQuestionItem[] = [
        ...state.editingQuestion.items!
      ];

      const switchTarget: EditingQuestionItem = isUpward
        ? questionItems[itemIndex - 1]
        : questionItems[itemIndex + 1];
      const switching: EditingQuestionItem = questionItems[itemIndex];

      const switched: EditingQuestionItem[] = questionItems.map(
        (questionItem: EditingQuestionItem, i: number) => {
          if (itemIndex === i) {
            return { ...switchTarget };
          } else if (
            (isUpward && i === itemIndex - 1) ||
            (!isUpward && i === itemIndex + 1)
          ) {
            return { ...switching };
          }

          return questionItem;
        }
      );

      state.editingQuestion = { ...state.editingQuestion, items: switched };
    },
    deleteQuestionItem: (state, action: { payload: number; type: string }) => {
      const itemIndex: number = action.payload;
      if (!('isDeleted' in state.editingQuestion.items![itemIndex])) {
        state.editingQuestion = {
          ...state.editingQuestion,
          items: state.editingQuestion.items!.filter(
            (_, i: number) => i !== itemIndex
          )
        };
        return;
      }

      const filtered: EditingQuestionItem[] =
        state.editingQuestion.items!.filter((_, i: number) => i !== itemIndex);
      const deletedItem: ExistingQuestionItem = {
        ...(state.editingQuestion.items![itemIndex] as ExistingQuestionItem),
        isDeleted: true
      };

      state.editingQuestion = {
        ...state.editingQuestion,
        items: [...filtered, deletedItem]
      };
    },
    restoreQuestionItem: (state, action: { payload: number; type: string }) => {
      const items: EditingQuestionItem[] = state.editingQuestion.items!.map(
        (item: EditingQuestionItem, i: number) => {
          if (i !== action.payload) return item;

          return { ...item, isDeleted: false };
        }
      );

      const deletedItems: EditingQuestionItem[] = items.filter(
        (item: EditingQuestionItem) => 'isDeleted' in item && item.isDeleted
      );
      const notDeletedItems: EditingQuestionItem[] = items.filter(
        (item: EditingQuestionItem) => !('isDeleted' in item && item.isDeleted)
      );

      state.editingQuestion = {
        ...state.editingQuestion,
        items: [...notDeletedItems, ...deletedItems]
      };
    },
    moveQuestionItem: (
      state,
      action: { payload: { activeId: number; overId: number } }
    ) => {
      const items: EditingQuestionItem[] = [...state.editingQuestion.items!];
      const oldIndex = items.findIndex(
        (item) => item.id === action.payload.activeId
      );
      const newIndex = items.findIndex(
        (item) => item.id === action.payload.overId
      );

      const newItems = arrayMove(items, oldIndex, newIndex);
      state.editingQuestion = { ...state.editingQuestion, items: newItems };
    },
    changeIsPublic: (state, action: { payload: boolean; type: string }) => {
      state.isPublic = action.payload;
    },
    addAnswerRange: (state) => {
      const newRange: EditingAnswerRange = {
        includesBoundaryValue: true,
        minValue: '0',
        isEqual: false
      };
      if (state.editingQuestion.ranges === undefined) {
        state.editingQuestion = {
          ...state.editingQuestion,
          ranges: [newRange]
        };

        return;
      }

      state.editingQuestion = {
        ...state.editingQuestion,
        ranges: [...state.editingQuestion.ranges, newRange]
      };
    },
    removeAnswerRange: (state, action: { payload: number; type: string }) => {
      if (state.editingQuestion.ranges!.length === 1) {
        state.editingQuestion = { ...state.editingQuestion, ranges: undefined };
        return;
      }

      state.editingQuestion = {
        ...state.editingQuestion,
        ranges: state.editingQuestion.ranges!.filter(
          (_, i: number) => i !== action.payload
        )
      };
    },
    changeRangeType: (
      state,
      action: { payload: { type: RangeType; index: number }; type: string }
    ) => {
      const makeNewMinValue = (): string | undefined => {
        if (
          action.payload.type === 'より小さい' ||
          action.payload.type === '以下'
        )
          return undefined;

        const previousMinValue: string | undefined =
          state.editingQuestion.ranges![action.payload.index].minValue;
        if (previousMinValue === undefined) return '';
        return previousMinValue;
      };

      const makeNewMaxValue = (): string | undefined => {
        if (
          action.payload.type === 'より大きい' ||
          action.payload.type === '以上'
        )
          return undefined;

        const previousMaxValue: string | undefined =
          state.editingQuestion.ranges![action.payload.index].maxValue;
        if (previousMaxValue === undefined) return '';
        return previousMaxValue;
      };

      const typesIncludesBoundaryValue: RangeType[] = [
        'の範囲内',
        '以上',
        '以下',
        'と等しい'
      ];

      const updatedRange: EditingAnswerRange = {
        id: state.editingQuestion.ranges![action.payload.index].id,
        minValue: makeNewMinValue(),
        maxValue: makeNewMaxValue(),
        includesBoundaryValue: typesIncludesBoundaryValue.includes(
          action.payload.type
        ),
        isEqual: action.payload.type === 'と等しい'
      };

      state.editingQuestion = {
        ...state.editingQuestion,
        ranges: state.editingQuestion.ranges!.map(
          (range: EditingAnswerRange, i: number) => {
            if (i === action.payload.index) return updatedRange;

            return range;
          }
        )
      };
    },
    changeRangeValue: (
      state,
      action: {
        payload: {
          index: number;
          value: string;
          isMin?: boolean;
          isEqual?: boolean;
        };
        type: string;
      }
    ) => {
      state.editingQuestion = {
        ...state.editingQuestion,
        ranges: state.editingQuestion.ranges!.map(
          (range: EditingAnswerRange, i: number) => {
            if (i === action.payload.index)
              return {
                ...range,
                minValue:
                  action.payload.isMin || action.payload.isEqual
                    ? action.payload.value
                    : range.minValue,
                maxValue:
                  !action.payload.isMin || action.payload.isEqual
                    ? action.payload.value
                    : range.maxValue
              };

            return range;
          }
        )
      };
    },
    changeQuestionCondition: (state, action) => {
      const newQuestionConditions: QuestionCondition[] = action.payload;

      state.editingQuestion = {
        ...state.editingQuestion,
        questionConditions: newQuestionConditions
      };
    },
    addGroup: (
      state,
      action: {
        payload: {
          groupedIndex?: number;
        };
      }
    ) => {
      const newGroup: QuestionGroup = {
        id: getNewId(0, state.groups),
        name: '',
        nextTo: {
          questionId: undefined,
          groupId: undefined
        },
        isOpen: true
      };
      const newGroupedQuestion: GroupedEditingQuestion = {
        groupId: newGroup.id,
        groupName: newGroup.name,
        nextTo: newGroup.nextTo,
        questions: [],
        isOpen: newGroup.isOpen
      };
      const groupedQuestions: EditingQuestionOrGroup[] = groupQuestions(
        state.questions,
        state.groups
      );
      const insertionIndex: number = getInsertionIndex(
        groupedQuestions,
        groupedQuestions.length,
        action.payload.groupedIndex
      );
      const insertedGroupedQuestions: EditingQuestionOrGroup[] =
        groupedQuestions.toSpliced(insertionIndex, 0, newGroupedQuestion);

      state.groups = updateNextTo(insertedGroupedQuestions, [
        ...state.groups,
        newGroup
      ]);
    },
    updateGroupName: (
      state,
      action: { payload: { groupId: number; name: string } }
    ) => {
      const updatedGroups: QuestionGroup[] = state.groups.map(
        (group: QuestionGroup) => {
          if (group.id === action.payload.groupId)
            return { ...group, name: action.payload.name };

          return group;
        }
      );

      state.groups = updatedGroups;
    },
    addQuestionIntoGroup: (
      state,
      action: { payload: { questionId: number; groupId: number } }
    ) => {
      const groupId: number = action.payload.groupId;
      const questionId: number = action.payload.questionId;
      const editingId: number | undefined =
        state.editingIndex === -1
          ? undefined
          : state.questions[state.editingIndex].id;
      //active質問の格納
      const originalTargetQuestion: EditingQuestion =
        questionId !== editingId
          ? state.questions.find(
              (question: EditingQuestion) => question.id === questionId
            )!
          : state.editingQuestion;
      //分岐関連
      const updatedQuestionConditions: QuestionCondition[] | undefined =
        originalTargetQuestion.questionConditions !== undefined
          ? originalTargetQuestion.questionConditions.filter(
              (condition: QuestionCondition) =>
                condition.childGroupId !== groupId
            )
          : undefined;
      const targetQuestion: EditingQuestion = {
        ...originalTargetQuestion,
        groupId,
        questionConditions: updatedQuestionConditions
      };

      const groupedQuestions: EditingQuestionOrGroup[] = groupQuestions(
        state.questions,
        state.groups
      );
      //targetの質問を除く作業
      const groupedQuestionsExceptTarget: EditingQuestionOrGroup[] =
        getGroupedQuestionsExceptTarget(groupedQuestions, questionId);

      const addedIntoGroup: EditingQuestionOrGroup[] =
        groupedQuestionsExceptTarget.map(
          (groupOrQuestion: EditingQuestionOrGroup) => {
            if ('groupName' in groupOrQuestion) {
              return groupOrQuestion.groupId === groupId
                ? {
                    ...groupOrQuestion,
                    questions: [...groupOrQuestion.questions, targetQuestion]
                  }
                : groupOrQuestion;
            }

            return groupOrQuestion;
          }
        );

      const added: EditingQuestion[] = addedIntoGroup.flatMap(
        expandGroupedQuestions
      );
      state.questions = added;
      state.groups = updateNextTo(addedIntoGroup, state.groups);

      if (editingId !== undefined) {
        state.editingIndex = added.findIndex(
          (question: EditingQuestion) => question.id === editingId
        );
      }
      if (editingId === questionId) {
        state.editingQuestion = {
          ...state.editingQuestion,
          groupId,
          questionConditions: updatedQuestionConditions
        };
      }
    },
    moveGroup: (
      state,
      action: { payload: { groupId: number; isUpward: boolean } }
    ) => {
      const editingId: number | undefined =
        state.editingIndex === -1
          ? undefined
          : state.questions[state.editingIndex].id;
      const groupedQuestions: EditingQuestionOrGroup[] = groupQuestions(
        state.questions,
        state.groups
      );
      const isTargetGroup = (groupOrQuestion: EditingQuestionOrGroup) =>
        'groupName' in groupOrQuestion &&
        groupOrQuestion.groupId === action.payload.groupId;

      const targetGroupIndex: number =
        groupedQuestions.findIndex(isTargetGroup);
      const moveTarget: EditingQuestionOrGroup =
        groupedQuestions.find(isTargetGroup)!;
      const groupedQuestionsExceptMoveTarget: EditingQuestionOrGroup[] =
        groupedQuestions.filter(
          (groupOrQuestion: EditingQuestionOrGroup) =>
            !isTargetGroup(groupOrQuestion)
        );

      const insertionIndex: number = action.payload.isUpward
        ? targetGroupIndex - 1
        : targetGroupIndex + 1;

      const moved: EditingQuestionOrGroup[] =
        groupedQuestionsExceptMoveTarget.toSpliced(
          insertionIndex,
          0,
          moveTarget
        );

      const reorderedQuestions: EditingQuestion[] = moved.flatMap(
        expandGroupedQuestions
      );
      state.questions = reorderedQuestions;
      state.groups = updateNextTo(moved, state.groups);

      if (editingId !== undefined) {
        state.editingIndex = reorderedQuestions.findIndex(
          (question: EditingQuestion) => question.id === editingId
        );
      }
    },
    extractQuestionFromGroup: (
      state,
      action: { payload: { activeId: string; overId: string } }
    ) => {
      const overGroupedIndex: number = getGroupedIndex(action.payload.overId);
      const activeGroupedIndex: number = getGroupedIndex(
        action.payload.activeId
      );
      const targetQuestionIndex: number = getQuestionIndexFromUniqueIdentifier(
        action.payload.activeId
      );
      const editingId: number | undefined =
        state.editingIndex === -1
          ? undefined
          : state.questions[state.editingIndex].id;

      const groupedQuestions: EditingQuestionOrGroup[] = groupQuestions(
        state.questions,
        state.groups
      );

      const originalTargetQuestion: EditingQuestion =
        editingId === state.questions[targetQuestionIndex].id
          ? state.editingQuestion
          : state.questions[targetQuestionIndex];
      const innerQuestionIds: number[] = state.questions
        .filter(
          (question: EditingQuestion) =>
            question.groupId === originalTargetQuestion.groupId
        )
        .map((question: EditingQuestion) => question.id);
      const targetQuestion: EditingQuestion = {
        ...originalTargetQuestion,
        groupId: undefined,
        questionConditions:
          originalTargetQuestion.questionConditions === undefined
            ? undefined
            : originalTargetQuestion.questionConditions.filter(
                (condition: QuestionCondition) =>
                  condition.childQuestionId === undefined ||
                  !innerQuestionIds.includes(condition.childQuestionId!)
              )
      };
      const groupedQuestionsExceptTargetQuestion = groupedQuestions.map(
        //active質問を除外する
        (groupOrQuestion: EditingQuestionOrGroup, index: number) => {
          if (index === activeGroupedIndex) {
            const groupedQuestion: GroupedEditingQuestion =
              groupOrQuestion as GroupedEditingQuestion;
            return {
              ...groupedQuestion,
              questions: groupedQuestion.questions.filter(
                (question: EditingQuestion) => question.id !== targetQuestion.id
              )
            };
          }

          return groupOrQuestion;
        }
      );

      // どっち向きに並び変わるか(activeよりoverが前ならばその質問/グループの前側に挿入し、後ろまたは質問が入っていたグループ自身ならばその質問/グループの後ろ側に挿入する)
      const insertBefore: boolean = activeGroupedIndex > overGroupedIndex;

      const insertionIndex: number = insertBefore
        ? overGroupedIndex
        : overGroupedIndex + 1;

      const insertedGroupedQuestions: EditingQuestionOrGroup[] =
        groupedQuestionsExceptTargetQuestion.toSpliced(
          insertionIndex,
          0,
          targetQuestion
        );

      const reorderedQuestions: EditingQuestion[] =
        insertedGroupedQuestions.flatMap(expandGroupedQuestions);
      state.questions = reorderedQuestions;
      state.groups = updateNextTo(insertedGroupedQuestions, state.groups);

      if (editingId !== undefined) {
        const newEditingIndex: number = reorderedQuestions.findIndex(
          (question: EditingQuestion) => question.id === editingId
        );
        state.editingIndex = newEditingIndex;
        state.editingQuestion =
          editingId === targetQuestion.id
            ? targetQuestion
            : reorderedQuestions[newEditingIndex];
      }
    },
    deleteGroup: (state, action: { payload: { groupId: number } }) => {
      const groupedQuestions: EditingQuestionOrGroup[] = groupQuestions(
        state.questions,
        state.groups
      );

      const deleted: EditingQuestionOrGroup[] = groupedQuestions.flatMap(
        (groupOrQuestion: EditingQuestionOrGroup) => {
          if (
            'groupName' in groupOrQuestion &&
            groupOrQuestion.groupId === action.payload.groupId
          )
            return groupOrQuestion.questions.map(
              (question: EditingQuestion) => ({
                ...question,
                groupId: undefined
              })
            );

          return groupOrQuestion;
        }
      );

      state.questions = deleted
        .flatMap(expandGroupedQuestions)
        .map((question: EditingQuestion) => {
          if (
            question.questionConditions === undefined ||
            question.questionConditions.length === 0
          )
            return question;

          return {
            ...question,
            questionConditions: question.questionConditions.filter(
              (condition) => condition.childGroupId !== action.payload.groupId
            )
          };
        });
      if (state.editingQuestion !== undefined) {
        state.editingQuestion = {
          ...state.editingQuestion,
          questionConditions:
            state.editingQuestion.questionConditions === undefined
              ? undefined
              : state.editingQuestion.questionConditions.filter(
                  (condition) =>
                    condition.childGroupId !== action.payload.groupId
                )
        };
      }

      state.groups = updateNextTo(
        deleted,
        state.groups.filter(
          (group: QuestionGroup) => group.id !== action.payload.groupId
        )
      );
      if (action.payload.groupId > 0)
        state.deletedGroupIds = [
          ...state.deletedGroupIds,
          action.payload.groupId
        ];
    },
    switchIsSaved: (state) => {
      state.isSaved = !state.isSaved;
    }
  }
});

export const {
  clearState,
  changeQuestionnaireName,
  changeQuestionType,
  changeFocusingIndex,
  initialize,
  changeInheritance,
  addQuestion,
  manageIsOpen,
  updateEditingQuestion,
  startEdit,
  quitEdit,
  replaceEditingQuestion,
  switchOrder,
  changeOrder,
  changeOrderWithInnerQuestion,
  deleteQuestion,
  restoreQuestion,
  addQuestionItem,
  updateQuestionItem,
  switchItemOrder,
  deleteQuestionItem,
  restoreQuestionItem,
  moveQuestionItem,
  changeIsPublic,
  addAnswerRange,
  removeAnswerRange,
  changeRangeType,
  changeRangeValue,
  changeQuestionCondition,
  addGroup,
  updateGroupName,
  addQuestionIntoGroup,
  moveGroup,
  extractQuestionFromGroup,
  deleteGroup,
  switchIsSaved
} = questionnaireSlice.actions;

export default questionnaireSlice.reducer;
